.post-container {
	min-height: 100vh;
	.post-contents {
		min-height: 40vh;
		padding: 2rem 5rem;
		display: flex;
		flex-direction: column;
		align-items: flex-start;
		h3 {
			font-family: source-serif-pro, serif;
			font-weight: 400;
			letter-spacing: 2px;
			color: #b7a485;
		}
		#settlement {
			display: flex;
			flex-direction: row;
			justify-content: space-between;
			align-items: flex-start;
			width: 100%;
			padding: 1rem 0rem;
			h3 {
				color: #b7a485;
				font-family: source-serif-pro, serif;
				text-align: left;
			}
			#settlement-description {
				width: 50%;
				color: black;
			
				h4 {
					text-align: left;
					font-weight: bold;
					letter-spacing: 1px;
				}
				p {
					text-align: left;
					.linktocontact{
						color: #b7a485 !important;
						text-decoration: underline !important;
					}
				}
			}
		}
	}
}
@media only screen and (max-width:600px){

    .post-container{
        .post-contents{
            padding:2rem 1rem;
            h3{
                width: 100%;
            }
            #settlement{
                display: flex;
                flex-direction: column;
                h3{
                    margin-bottom: 2rem;
                }
                #settlement-description{
                    width: 100%;
                    color: black;
                }
            }

        }
    }


}
@media only screen and (min-width:601px) and (max-width:922px){

    .post-container{
        .post-contents{
            padding:2rem 1rem;
            h3{
                width: 100%;
            }
            #settlement{
                display: flex;
                flex-direction: column;
                
                h3{
                    margin-bottom: 2rem;
                }
                #settlement-description{
                    width: 90%;
                    color: black;
                }
            }

        }
    }
}