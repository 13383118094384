.team-container{
    min-height: 100vh;
    .team-contents{
        min-height: 40vh;
        padding: 2rem 5rem;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        color: #b7a485;
        h3{
        font-family: source-serif-pro, serif;
		font-weight: 400;
        letter-spacing: 2px;
        }
        .placeholder-container{
            display: flex;
            flex-direction: row;
            justify-content: space-evenly;
            align-items: center;
            width: 100%;
        }
    }
}
.team-card-container{
    display:flex;
    flex-direction:row;
    width:100%;
    flex-wrap:wrap;
    #team-card{
        width: 33%;
        margin-top:2rem;
        border:none;
    }
}
@media only screen and (max-width:600px){
   
   .team-card-container{ display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    #team-card{
        width: 100%;
    }
   }
   .team-container{
   .team-contents{
       padding: 2rem 1rem;
   }
}
}
@media only screen and (min-width:601px) and (max-width:922px){
    .team-container{
        
        .team-contents{
            padding: 2rem 1rem;
        }
     }
     .team-card-container{ 
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        #team-card{
            width: 100%;
        }
       }

}