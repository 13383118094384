.headerContainer {
	width: auto;
	background-color: #092b2e;
	overflow-x: hidden !important;
	min-height: 5vh;
    overflow-y:hidden;
     overflow-x:hidden;
     position: relative;
	 display: flex;
	 flex-direction: column;
	 justify-content: center;
	.headerbtn {
		width: auto;
		min-height: 56px;
		color: #b7a485;
		border-color: #b7a485;
		border-radius: 0;
		align-self: flex-end;
	}
	.headerbtn:active {
		background-color: #b7a485;
		color: white;
		border-color: white;
	}
	.headerbtn:hover {
		background-color: #b7a485;
		color: white;
		border-color: white;
	}
	#mobile-btn{
		border: none;
		box-shadow: none;
		align-self: center;

	}
	#mobile-btn:hover{
		border: 2px solid #b7a485;
		background-color: #b7a485;
		border-radius: 0;
		box-shadow: none;
	}
	.headerbtn-mobile{
		width: auto;
		min-height: 56px;
		color: #b7a485;
		border-color: #b7a485;
		border-radius: 0;
		margin:2rem 1rem;
	}
	.headerbtn-mobile:hover {
		background-color: #b7a485;
		color: white;
		border-color: white;
	}
	.headerbtn-mobile:active {
		background-color: #b7a485;
		color: white;
		border-color: white;
	}

	.nav-elements {
		display: flex;
		flex-direction: row;
		justify-content: space-between;
		flex-wrap: wrap;
		color: white;
		margin-top: 3rem;
		font-family: proxima-nova, sans-serif;
		font-weight: 400;
		font-style: normal;
        
		height: 34px;

		li {
			letter-spacing: 3px;
			padding-bottom:0.5rem;
		}
		a{
			text-decoration:none;
			color: inherit;
		}
		li:hover {
			border-bottom: 2px solid #b7a485;
			cursor: pointer;
		}
		li:active{
			border-bottom: 2px solid #b7a485;
		}
	}
}
@media only screen and (min-width:922px) and (max-width:1180px){
	.nav-elements{
		li{
			font-size: 13px;
		}
	}
	#header-right{
		width: 60vw !important;
	}
}

