@media only screen and (max-width:600px){
    .hero-contact{
        h1{
            font-size: 20px;
        }
        h3{
            font-size: 16px;
        }
    }
}

