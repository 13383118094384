.content-section-container {
	min-height: 70vh;
	width: 100%;
	background-image: url('../../assets/hero_image_2.jpg');
	background-repeat: no-repeat;
	background-size: cover;
	background-position: right 100% bottom 45%;
	position: relative;
	display: flex;
	flex-direction: row-reverse;
	.content-section-btn {
		box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
		height: 3rem;
		width: 20rem;
		border-radius: 0;
		background: #b7a485;
		border: 0px;
		color: white;
		font-family: proxima-nova, sans-serif;
		font-weight: 400;
		font-style: normal;
		letter-spacing: 2px;
	}
	.content-section-btn:hover {
		color: #b7a485;
	}
}
@media only screen and (max-width:600px){

	.content-section-container{
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: center;
		#content-section-card{
			min-width: 40% !important;
			max-width: 95% !important;
			margin: 2rem !important;
		}
		#content-section-card-body{
			justify-content: center !important;
		}
	}
	#success-notification{
		width: 100% !important;
	}
	
}
@media only screen and (min-width:601px) and (max-width:922px){
	.content-section-container{
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: center;
		#content-section-card{
			min-width: 80% !important;
			margin: 2rem !important;
		}
		#content-section-card-body{
			justify-content: center !important;
		}
		#success-notification{
			width: 100% !important;
		}
	}
}
@media only screen and (min-width:922px) and (max-width:1180px){
	#content-section-card{
		max-width: 60% !important;
	}
}
