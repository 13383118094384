.facts-container {
	min-height: 100vh;
	.facts-content {
		min-height: 40vh;
		padding: 2rem 2rem;
		display: flex;
		flex-direction: column;
		justify-content: flex-start;
		align-items: center;
        h3{
            font-family: proxima-nova, sans-serif;
            font-weight: 400;
            letter-spacing: 2px;
            align-self: flex-start;
            text-align: left;
            width:80%;
            margin-bottom: 5rem;
            color: #b7a485;
        }
        h5{
            align-self: start;
            text-align: left;
            color: black;
            letter-spacing: 1px;
        }
        #facts-guide{
            padding: 2rem 2rem;
            display: inline-block;
            align-self: flex-start;
            text-align: start;
            width: 100%;
            font-family: proxima-nova, sans-serif;
            letter-spacing: 1px;
            li{
                padding: 0.5rem 0rem;
            }
        }
    
	}
}
@media only screen and (max-width:600px) {
    .facts-container{
        .facts-content{
            padding:2rem 1rem;
            h3{width: 100%;
                
            }
            #facts-guide{
                padding: 2rem 0rem;
            }
        }
    }
}
