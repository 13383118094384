.landing-container {
	min-height: 100vh;
	width: 100%;
    overflow-y: auto;
	.hero {
		background-image: url('../../assets/hero_image.jpg');
		background-repeat: no-repeat;
		background-size: cover;
		background-position: left;
		background-color: rgba(9, 43, 46, 0.8);
		background-blend-mode: multiply;
		min-height: 50vh;
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: center;
		#hero-contents {
			position: absolute;
			right: 0;
			display: flex;
			flex-direction: column;
			justify-content: center;
			align-items: flex-start;
			width: 50%;
			height: auto;

			h4 {
				color: #b7a485;
				font-family: proxima-nova, sans-serif;
				font-weight: 400;
				font-style: normal;
			}
			h1 {
				text-align: left;
				color: white;
				font-family: source-serif-pro, serif;
				font-weight: 400;
				font-style: normal;
				letter-spacing: 2px;
				width: 100%;
			}
			h6 {
				color: #b7a485;
				font-family: proxima-nova, sans-serif;
				font-weight: 400;
				font-style: normal;
				letter-spacing: 2px;
			}
			#hero-btn {
				margin: 1rem 0rem 3rem 0rem;
				width: 8rem;
				color: #b7a485;
				border-color: #b7a485;
				border-radius: 0;
				font-family: proxima-nova, sans-serif;
				font-weight: 400;
				font-style: normal;
				letter-spacing: 2px;
			}
			#hero-btn:active {
				background-color: #b7a485;
				color: white;
				border-color: white;
			}
			#hero-btn:hover {
				background-color: #b7a485;
				color: white;
				border-color: white;
			}
		}
	}
	.hero-2 {
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
		min-height: 20vh;
		text-align: center;
		width: 100%;
		#hero-2-contents {
			padding-top: 4rem;
			h4 {
				color: #b7a485;
				font-family: proxima-nova, sans-serif;
				font-weight: 400;
				font-style: normal;
				letter-spacing: 1px;
				padding: 0rem 10rem;
			}
			h6 {
				font-family: proxima-nova, sans-serif;
				font-weight: 400;
				text-align: justify;
				padding: 0rem 20rem;
			}
			#hero-2-btn {
				margin: 3rem 0rem;
				width: 8rem;
				background-color: #b7a485;
				color: white;
				border-color: #b7a485;
				border-radius: 0;
				font-family: proxima-nova, sans-serif;
				font-weight: 400;
				font-style: normal;
				letter-spacing: 2px;
				border: none;
			}
		}
		.litigation {
			min-height: 30vh;
			padding: 4rem 2rem;
			display: flex;
			flex-direction: column;
			align-items: center;
			width: 100%;
			h1 {
				font-family: source-serif-pro, serif;
				font-weight: 400;
				font-weight: bold;
				text-align: center;
				color: #b7a485;
			}
		}
	}
}
@media only screen and (max-width:600px){
.landing-container{
	.hero{
		min-height: 60vh;
		#hero-contents{
			width:90%;
			h1{
			font-size: 25px;	
			}
		}
	}
	.hero-2{
		#hero-2-contents{
			h6{
				padding:0rem 2rem;
			}
			h4{
				padding: 0rem 2rem;
			}
		}
	}
}

}
@media only screen and (min-width:601px) and (max-width:922px){

	.landing-container{
		.hero{
			min-height: 60vh;
			#hero-contents{
				width:60%;
				h1{
				font-size: 25px;	
				}
			}
		}
		.hero-2{
			#hero-2-contents{
				h6{
					padding:0rem 1rem;
				}
				h4{
					padding: 0rem 1rem;
				}
			}
		}
	}
}
@media only screen and (min-width:922px) and (max-width:1180px){
	.landing-container{
		.hero{
			#hero-contents{
				width: 60vw;
			}
		}
	}
	}
