.about-container{
    min-height: 100vh;
    .about-contents{
        min-height: 40vh;
        padding: 2rem 5rem;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: center;
        color: #b7a485;
        h3{
            font-family: proxima-nova, sans-serif;
            font-weight: 400;
            letter-spacing: 2px;
            align-self: flex-start;
            text-align: left;
            width:30%;
        }
        #funding-guide{
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            align-items: flex-start;
            width: 100%;
            border-bottom: 1px solid black;
            padding: 3rem 0rem;
            h3{
                color:#b7a485;
                font-family:source-serif-pro, serif;
                text-align: left;
            }
            #funding-guide-description{
                width: 50%;
                color: black;
                h4{
                    text-align: left;
                    font-weight: bold;
                    letter-spacing: 1px;
                    
                }
            p{
               
                text-align: left;
              
            }
        }
        }
    }
}
@media only screen and (max-width:600px){
    .about-container{
        .about-contents{
            padding:2rem 1rem;
            h3{
                width: 100%;
            }
            #funding-guide{
                display: flex;
                flex-direction: column;
                h3{
                    margin-bottom: 2rem;
                }
                #funding-guide-description{
                    width: 100%;
                    color: black;
                }
            }

        }
    }
  

}

@media only screen and (min-width:601px) and (max-width:922px){
    .about-container{
        .about-contents{
            padding:2rem 1rem;
            h3{
                width: 100%;
            }
            #funding-guide{
                display: flex;
                flex-direction: column;
                
                h3{
                    margin-bottom: 2rem;
                }
                #funding-guide-description{
                    width: 90%;
                    color: black;
                }
            }

        }
    }
  

}