.footer-container{
    width: auto;
	background-color: #092b2e;
	overflow-x: hidden !important;
	min-height: 15vh;
    overflow-y:hidden;
     overflow-x:hidden;
     position: relative;
     display: flex;
     flex-direction: row;
     justify-content: space-between;
     align-items: center;
     color: #b7a485;
     padding: 5rem 2rem;
     #footer-nav{
         display: flex;
         flex-direction: row;
         justify-content: space-evenly;
         align-self: flex-end;
         width: 50%;
         font-family: proxima-nova, sans-serif;
         font-weight: 400;
         font-style: normal;
         letter-spacing: 2px;
         li{
             text-decoration: none;
             list-style-type: none;
             cursor: pointer;
             
         }
         #footer-link{
            color: #b7a485 !important;
         }
     }
     h6{
         align-self: flex-end;
         font-family: proxima-nova, sans-serif;
         font-weight: 400;
         font-style: normal;
         letter-spacing: 2px;
     }
     #wcjlogo{
        width:15vw
    }
    
}
@media only screen and (max-width:600px){
    .footer-container{
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-between;
        padding: 3rem 1rem;
        min-height: 35vh;
        #footer-nav{
            align-self: center;
            width: 100vw;
        }
        h6{
            align-self: center;
            width: 100vw;
        }
        #wcjlogo{
            width:35vw
        }
    }
}
@media only screen and (min-width:601px) and (max-width:922px){
    .footer-container{
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-between;
        padding: 3rem 1rem;
        min-height: 40vh;
        #footer-nav{
            align-self: center;
            width: 100vw;
        }
        h6{
            align-self: center;
            width: 100vw;
        }
        #wcjlogo{
            width:25vw
        }
    }

}