#menuItem{
    font-family: proxima-nova, sans-serif ;
		font-weight: 400;
		font-style: normal;
        letter-spacing: 3px;
        padding-bottom: 0.5rem;
        
}
#menuItem:hover{
    border-bottom: 2px solid #b7a485 ;
    
}
