#learn-more{
    width: 10rem;
    border-radius:0;
    background:#b7a485;
    border:0px;
    color:white;
    font-family: proxima-nova, sans-serif;
    font-weight: 400;
    font-style: normal;
    letter-spacing: 2px;
    #learn-more:hover{
        color:#b7a485;
    }
}
@media only screen and (max-width:600px){
    #lit-container{
        display: flex !important;
        flex-direction: column !important;
        justify-content: space-evenly !important;
        #lit-text-container{
            width:100%!important;
        }
    }
}
@media only screen and (min-width:601px) and (max-width:922px){
    #lit-container{
        display: flex !important;
        flex-direction: column !important;
        justify-content: space-evenly !important;
        #lit-text-container{
            width:100%!important;
        }
    }
}

