.fund-container{
    min-height: 100vh;
    .fund-contents{
        min-height: 40vh;
        padding: 2rem 5rem;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        color: #b7a485;
        h3{
            font-family: proxima-nova, sans-serif;
            font-weight: 400;
            letter-spacing: 2px;
            align-self: flex-start;
            }
          
    }
}